import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import { DashboardHeader, GetCash, PlayStoreReviews } from "../../components";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import ServicesAPI from "../../services/services";
import Loader from "../../components/Loader/Loader";
import { storeLeadID } from "../../utils/storage";
import LoanRepayment from "../../components/GetCash/LoanRepayment";
import useResponse from "../../hooks/useResponse";
import { useDispatch, useSelector } from "react-redux";
import { addAccountId } from "../../redux/slices/userSlice";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";
import EMIRepayment from "../../components/GetCash/EMIRepayment";
import { storeCustomerTypeAndAppVersion } from "../../lib/script";

const packageJson = require("../../../package.json");

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { dashboardAPI } = new ServicesAPI();

  useEffect(() => {
    dispatch(addAccountId(""));

    WebEngageUserAttributes("userId", user?.customerID);
    WebEngageUserAttributes("phone", `91${user?.mobile}`);
    WebEngageTrackingEvent("Dashboard");

    // if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const lat = position.coords.latitude;
    //       const lon = position.coords.longitude;
    //     },
    //     (error) => {
    //       console.log(error.message);
    //     }
    //   );
    // }

    const fetchAPI = async () => {
      const res = await dashboardAPI();

      // console.log("res---------", res);

      if (res && res.statusCode === 200) {
        if (res.data.showStepDashboardMessages && Object.keys(res?.data?.step).length > 0) {
          const originalData = res.data;
          const stepData = res.data.step;
          setData({ ...originalData, ...stepData });
        } else {
          setData(res.data);
        }

        const leadID = res?.data?.lead?.leadID;

        if (leadID) {
          storeLeadID(leadID);
        } else {
          storeLeadID("");
        }

        // Event Tracking
        if (res?.data?.lead?.status === "Approved") {
          WebEngageTrackingEvent("Loan Application Approved", {
            "Loan Amount": parseInt(res.data.loanDetails.totalAmount),
            "Processing Fee": parseInt(res.data.loanDetails.processingAmount),
            "Net Disbursal amount": parseInt(res.data.loanDetails.finalAmount),
            "Repayment Date": new Date(res.data.loanDetails.repayDate),
            "Repayment Amount": parseInt(res.data.loanDetails.repayAmount),
            customer_type: res.data.customer_type,
            app_version: packageJson.version,
          });
        }

        WebEngageUserAttributes("user_type", res.data.customer_type);

        // Store Customer Type & App Version
        storeCustomerTypeAndAppVersion(res.data.customer_type, packageJson.version);
      }

      setLoading(false);
    };

    fetchAPI();
  }, []);

  // console.log("data----------", data.customer_type);
  // console.log("version-----------", packageJson.version);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <DashboardHeader />

      <div className={styles.home}>
        {data?.lead?.status === "Disbursed" || data?.lead?.status === "Part Payment" ? (
          <LoanRepayment data={data} />
        ) : (
          <GetCash data={data} />
        )}

        {/* <EMIRepayment /> */}

        {/* Credit Report */}
        {/* <div className={styles.credit__score}>
          <div className={styles.content}>
            <h3>Access your Credit Report</h3>
            <p>Check and improve your financial health.</p>
            <Link to="/">
              <span>Check</span>
              <FaArrowRightLong />
            </Link>
          </div>
          <div className={styles.image}>
            <img src="/images/credit-score.png" alt="" />
          </div>
        </div> */}

        {/* Playstore Reviews */}
        {/* <PlayStoreReview /> */}
        {/* <PlayStoreReviews /> */}

        {/* Our Process */}
        <div className={styles.our__process}>
          <div>
            <img src="/images/no-hidden-charge.png" alt="" />
            <p>
              No Hidden <br /> Charges
            </p>
          </div>
          <div>
            <img src="/images/quick-disbursal.png" alt="" />
            <p>
              Quick <br /> Disbursed
            </p>
          </div>
          <div>
            <img src="/images/simple-process.png" alt="" />
            <p>
              Simple & <br /> Secure Process
            </p>
          </div>
        </div>

        {/* Refer & Earn */}
        {/* <div className={styles.refer__earn}>
          <img src="/images/refer-earn.png" alt="" />
        </div> */}

        {/* Affordable */}
        {/* <div className={styles.affordable}>
          <h1>Affordable and Accessible</h1>
          <p>Craft With ❤️ in New Delhi, India</p>
        </div> */}
      </div>
    </>
  );
}
