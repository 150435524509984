import React from "react";
import AppRoute from "./routes/AppRoute";
import Layout from "./layout/Layout";

export default function App() {
  return (
    <>
      {/* <Layout /> */}
      <AppRoute />
    </>
  );
}
