import api, { apiData } from "../utils/api";
import { serviceError } from "../lib/serviceError";
import { getLeadID } from "../utils/storage";
import { utmSourceStorage } from "../lib/script";
class AfterOnboardAPI {
  // Loan Approved API
  async loanApprovedAPI() {
    try {
      const endpoint = "customer_onboarding/approval-view";
      const req = {
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Add Reference API
  async addReferenceAPI(values) {
    try {
      const endpoint = "customer_onboarding/reference";
      const req = {
        name1: values.nameOne,
        name2: values.nameTwo,
        mobileNo1: values.mobileOne,
        mobileNo2: values.mobileTwo,
        relation1: values.relationOne,
        relation2: values.relationTwo,
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Selfie Verification API
  async selfieVerificationAPI(image) {
    try {
      const endpoint = "customer_onboarding/verify_selfie";

      const formData = new FormData();

      formData.append("image", image);
      formData.append("leadID", Number(localStorage.getItem("leadID")));

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const { data } = await api.post(endpoint, formData, config);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Get Bank Details API
  async getBankDetailsAPI() {
    try {
      // const endpoint = "customers/finbox-account-details";
      // const req = {};
      // const { data } = await api.get(endpoint, req);
      // return data;

      const req = {
        url: "customers/finbox-account-details",
        method: "GET",
        data: {},
      };

      const res = await apiData(req);
      return res;
    } catch (error) {
      console.log("Error getting in finbox account details", error);
      // serviceError(error);
    }
  }

  // Add Bank Details API
  async addBankDetailsAPI(values) {
    try {
      const endpoint = "customer-bank-account/bank-details";
      const req = {
        accountHoldersName: values.accountHolderName,
        accountNo: `${values.accountNo}`,
        confirmedAccountNo: `${values.confirmedAccountNo}`,
        ifsc: values.ifsc,
        bankName: values.bankName,
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Get Name with IFSC
  async getBankNameWithIFSC(ifsc) {
    try {
      const endpoint = `common/bank-details?ifsc=${ifsc}`;
      const req = {};

      const { data } = await api.get(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Set Emandate
  async setEmandate(accountId) {
    try {
      const endpoint = "customer_onboarding/set-emandate";
      const req = {
        accountId: accountId,
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Set Emandate V2 Callback
  async setEmandateV2(accountId) {
    try {
      const endpoint = "customer_onboarding/set-emandateV2";
      const req = {
        accountId: accountId,
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Penny Drop
  async pennyDrop(accountId) {
    try {
      const endpoint = "customer_onboarding/penny-drop";
      const req = {
        accountId: accountId,
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Customer Bank Account
  async customerBankAccountAPI() {
    try {
      const endpoint = `customer-bank-account?leadId=${Number(localStorage.getItem("leadID"))}`;
      const req = {};

      const { data } = await api.get(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Confirm Bank Account
  async confirmBankAccountAPI(mandateId, accountID) {
    const req = {
      url: "customer-bank-account/confirm",
      method: "POST",
      data: {
        leadID: Number(localStorage.getItem("leadID")),
        mandateId: Number(mandateId),
        accountID: accountID,
      },
    };

    const res = await apiData(req);

    return res;

    // try {
    //   const endpoint = `customer-bank-account/confirm`;
    //   const req = {
    //     leadID: Number(localStorage.getItem("leadID")),
    //     mandateId: Number(mandateId),
    //     accountID: accountID,
    //   };

    //   const { data } = await api.post(endpoint, req);

    //   return data;
    // } catch (error) {
    //   serviceError(error);
    // }
  }

  // Key Fact Statement
  async keyFactStatementsAPI() {
    try {
      const endpoint = `customer_onboarding/kfs-payday?leadId=${getLeadID()}`;
      const req = {};

      const { data } = await api.get(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Key Fact Acceptance
  async keyFactAceeptanceAPI() {
    try {
      const endpoint = `customer_onboarding/kfs-facts-acceptance`;
      const req = {
        leadId: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // One Page Get Details
  async onePageGetDetailsAPI() {
    try {
      const endpoint = `customers/one-page/${getLeadID()}`;
      const req = {};

      const { data } = await api.get(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Check Repeat Case
  async checkRepeatCaseAPI(values, loanAmount) {
    const host = window.location.href.split("/");

    try {
      const endpoint = `customers/check-repeat-case/${getLeadID()}`;
      const req = {
        loanRequeried: loanAmount,
        loanPurpose: values.loanPurpose,
        marrital: values.maritalStatus,
        education: values.qualification,
        employeeType: values.employementType,
        modeOfPayment: values.modeOfPayment,
        companyName: values.companyName,
        industry: values.industry,
        designation: values.designation,
        monthlyIncome: values.monthlySalary,
        salary_date: values.salaryDate,
        residenceType: values.address.type,
        callBackUrl: host[0] + "//" + host[2] + "/finbox",
      };

      const { data } = await api.post(endpoint, req);

      return data;
    } catch (error) {
      serviceError(error);
    }
  }
}

export default AfterOnboardAPI;
