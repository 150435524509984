import React, { useEffect } from "react";
import { Formik } from "formik";
import styles from "./EnterMobile.module.scss";
import Loader from "../../components/Loader/Loader";
import { CustomButton, Header } from "../../components";
import { MdOutlineCall } from "react-icons/md";
import { entermobileNumberValidation } from "../../validations/auth.validation";
import ServicesAPI from "../../services/services";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearRegisterData } from "../../redux/slices/registerSlice";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function EnterMobile() {
  const { enterMobileNumber } = new ServicesAPI();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRegisterData());
    handleRedirect();
  }, []);

  const handleRedirect = () => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/home");
    }
  };

  return (
    <div className={`wrapper ${styles.home}`}>
      <Formik
        initialValues={{ mobileNumber: "" }}
        validationSchema={entermobileNumberValidation}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);

          setTimeout(async () => {
            const res = await enterMobileNumber(values.mobileNumber);

            if (res && res.statusCode === 200) {
              navigate("/enter-otp", { state: { mobileNumber: values.mobileNumber } });
            } else {
              setFieldError("mobileNumber", res.message);
            }

            WebEngageTrackingEvent("OTP Initiated");

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <Header />

                <div className="mb-2">
                  <p className={styles.label}>Enter Mobile Number</p>
                  <p className={styles.sub__label}>Please enter your mobile number</p>
                </div>

                <div className="form-group">
                  <div className={styles.call__icon}>
                    <MdOutlineCall size={28} color="#9a9a9a" />
                  </div>
                  <input
                    type="tel"
                    className={styles.form__input}
                    placeholder="Enter your mobile number"
                    name="mobileNumber"
                    value={values.mobileNumber}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (input != "000000000000" && /^\d*$/.test(input)) {
                        setFieldValue("mobileNumber", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue("mobileNumber", e.target.value.trim());
                    }}
                    autoComplete="off"
                    maxLength={10}
                  />

                  {touched.mobileNumber && errors.mobileNumber ? (
                    <div className="error-msg">{errors.mobileNumber}</div>
                  ) : null}
                </div>
              </div>

              <div className="vertical-column-bottom">
                <CustomButton text="Get OTP" type="submit" />

                <div className={styles.footerBar}>
                  <p style={{ marginBottom: 10 }}>
                    Are you in need of instant money? Ram Fincorp is here to help! With our instant personal loan app,
                    you can get a loan of up to ₹2 lakhs within a few minutes & transfer the money directly to your bank
                    account.
                  </p>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    What We Offer:
                  </p>

                  <ul className={styles.disc}>
                    <li>Loan amount: ₹ 1,000 - ₹2 lakhs</li>
                    <li>Directly in your bank account within 2 minutes</li>
                    <li>Flexible repayment options upto 3 Months</li>
                    <li>100% online process - no queues, no fuss, no hassle</li>
                    <li>Minimal paperwork - just PAN & Aadhaar</li>
                    <li>No collateral needed</li>
                    <li>Pay off your loan early anytime without any foreclosure penalty</li>
                  </ul>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    Example of How Personal Loans from the Ram Fincorp App Work
                  </p>
                  <p>Let's say you borrow ₹5,00,000 for 3 years, then your:</p>
                  <ul className={styles.disc}>
                    <li>Processing fee (3% of loan amount + 18% GST + ₹500 stamp duty) cost to: ₹18,200</li>
                    <li>Interest rate: 20% p.a. (on reducing principal balance interest calculation)</li>
                    <li>Your EMI will be: ₹18,582</li>
                    <li>Total amount to be paid: ₹18,582 x 36 = ₹6,68,952</li>
                    <li>Total interest paid: ₹1,68,944</li>
                    <li>Total cost of loan: ₹1,68,944 + ₹18,200 = ₹1,87,144</li>
                    <li>APR (Annual Percentage Rate): 22.7%</li>
                  </ul>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    Personal Loan Eligibility Criteria
                  </p>
                  <ul className={styles.disc}>
                    <li>Age: 21-55 years</li>
                    <li>
                      Minimum in-hand monthly salary requirement: ₹18,000 in metro cities and ₹15,000 in non-metros
                    </li>
                    <li>Should be a resident of India</li>
                  </ul>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    How Do You Apply for an Instant Personal Loan on the Ram Fincorp App?
                  </p>
                  <p>Personal loan application is easy on the Ram Fincorp Mobile app.</p>
                  <ul className={styles.disc}>
                    <li>Download the Ram Fincorp app</li>
                    <li>Fill in your details</li>
                    <li>Choose how much instant loan amount you want and your preferred tenure</li>
                    <li>Get money in your account in 2 minutes only.</li>
                  </ul>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    Documents required for Instant Personal Loan
                  </p>
                  <ul className={styles.disc}>
                    <li>PAN</li>
                    <li>Aadhaar card</li>
                  </ul>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    Ram Fincorp Charges
                  </p>
                  <ul className={styles.disc}>
                    <li>Interest: Starting from 18% p.a.</li>
                    <li>Processing fee: 2 - 10% of loan amount</li>
                    <li>Late payment: ₹500 on bounce</li>
                    <li>Bounced payment: ₹500</li>
                    <li>APR: Starting from 16.75%</li>
                  </ul>
                  <p style={{ marginTop: 10 }}>
                    At Ram Fincorp, we believe in transparency. All Personal Loan fees and charges are clearly
                    communicated to our customers through the agreement shared on their registered email IDs. You can
                    also access your loan details 24/7 through our Instant Loan app.
                  </p>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    About Ram Fincorp
                  </p>
                  <p>
                    <strong>Ram Fincorp Instant Loan App</strong> is owned by{" "}
                    <strong>Kundanmal Finance Pvt. Ltd.</strong> and provides lending services primarily through our
                    RBI-registered NBFC arm, <strong>RK Bansal Finance Pvt. Ltd.</strong> It is an instant loan app
                    designed to empower individuals across various income groups to achieve their financial goals.
                  </p>

                  <p>
                    The app offers a wide range of financial products, including{" "}
                    <strong>short-term instant cash loans, long-term personal loans</strong>, and{" "}
                    <strong>customized EMI-based plans</strong> to suit diverse customer needs. Ram Fincorp ensures a
                    seamless and <strong>100% digital loan application process</strong>, making it quick and hassle-free
                    for users to access credit.
                  </p>

                  <p className={styles.title} style={{ marginTop: 20 }}>
                    How to contact Ram Fincorp
                  </p>
                  <p>
                    Telephone: <span style={{ color: "blue" }}>+91 98999-85495</span>
                  </p>
                  <p>Email: info@ramfincorp.com</p>
                  <p>
                    <strong>
                      We're not just an personal loan app but also here for you in person! You can find us at:
                    </strong>
                    . 8/9, Pusa Road, Block 8, WEA, Karolbagh, New Delhi, 110005
                  </p>
                  <p>Download Ram Fincorp Now!!!</p>
                </div>
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
