import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./BankList.module.scss";
import MainHeader from "../../components/Header/MainHeader";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import useResponse from "../../hooks/useResponse";
import { useDispatch } from "react-redux";
import { addAccountId, changeLoanApproved } from "../../redux/slices/userSlice";
import ConfirmBankModal from "../../components/Modal/ConfirmBankModal";
import { WebEngageTrackingEvent } from "../../lib/webengage";
// import { addAccountId } from "../../redux/slices/registerSlice";

export default function BankList() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({
    item: 0,
    mandateId: null,
    accountID: null,
  });
  const [openPanModal, setOpenPanModal] = useState({ bottom: false, bankDetails: null });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { customerBankAccountAPI, confirmBankAccountAPI } = new AfterOnboardAPI();
  const { handleStep } = useResponse();

  useEffect(() => {
    const fetchAPI = async () => {
      const res = await customerBankAccountAPI();
      if (res) {
        if (res.statusCode === 200) {
          // const data = res.data.sort((a, b) => b.status.localeCompare(a.status));
          const data = res.data;
          setSelected({ ...selected, mandateId: data[0]?.mandateId, accountID: data[0]?.accountID });
          setOpenPanModal({ ...openPanModal, bankDetails: data[0] });
          setData(data);
        } else {
          Swal.fire({
            icon: "error",
            text: res.message,
          });
        }
      }
      setLoading(false);
    };

    fetchAPI();
  }, []);

  const handleConfirmBankAccount = async () => {
    setOpenPanModal({ ...openPanModal, bottom: false });
    dispatch(changeLoanApproved(true));
    setLoading(true);
    const { mandateId, accountID } = selected;

    const res = await confirmBankAccountAPI(mandateId, accountID);

    // console.log("res----------", res);

    if (res && res.statusCode === 200) {
      handleStep(accountID);
    } else if (res.statusCode === 400 && res?.data?.status === "Not Verified") {
      dispatch(addAccountId(accountID));
      navigate(`${res.data.route}`);
    } else {
      Swal.fire({
        icon: "warning",
        text: res.message,
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 5000));

    setLoading(false);
  };

  // console.log("data---------", data);

  // console.log("openPanModal---------", openPanModal.bankDetails.bank);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="wrapper auto-height">
        <div className="vertical-column-layout">
          <div className="vertical-column-top">
            <MainHeader title="Bank Details" />
            <p className="label mb-2">Kindly select bank account for loan disbursal</p>

            {data?.map((item, index) => (
              <div
                className={`${styles.bankListCard} ${index === selected.item ? styles.active : ""}`}
                onClick={() => {
                  setSelected({ ...selected, item: index, mandateId: item.mandateId, accountID: item.accountID });
                  setOpenPanModal({ ...openPanModal, bankDetails: item });
                }}
                key={index}
              >
                <div className={styles.bankInfo}>
                  <div className={styles.logo}>
                    <img src={item.bankIcon} alt="" />
                  </div>
                  <div className={styles.name}>{item.bank}</div>
                </div>
                <div className={styles.accountInfo}>
                  <p>Account Number</p>
                  <span>{item.accountNo}</span>
                </div>
                <div className={`${styles.accountInfo} ${styles.verified}`}>
                  <div>
                    <p>IFSC code</p>
                    <span>{item.bankIfsc}</span>
                  </div>
                  <div>
                    {item.status === "Verified" && (
                      <div className={styles.accountVerifiedBottom}>
                        <img src="/images/verifiedicon.svg" alt="" /> <span>Verified</span>
                      </div>
                    )}
                  </div>
                </div>

                {index === selected.item && item.status === "Verified" && (
                  <div className={styles.accountVerified}>
                    <img src="/images/verifiedicon.svg" /> <span>Verified</span>
                  </div>
                )}
              </div>
            ))}

            <div className={styles.addNewAccount}>
              <Link to="/bank-details" state={{ backScreen: "/bank-list" }}>
                or add a new bank account
              </Link>
            </div>
          </div>

          {/* <div className="vertical-column-bottom" onClick={() => handleConfirmBankAccount()}>
            <button className="button">
              Continue <i className="shine"></i>
            </button>
          </div> */}

          <br />
          <br />
          <br />
          <br />

          <div
            className={styles.footer}
            onClick={() => {
              if (openPanModal?.bankDetails?.status === "Verified") {
                setOpenPanModal({ ...openPanModal, bottom: true });
              } else {
                handleConfirmBankAccount();
              }

              WebEngageTrackingEvent("Bank Select for Disbursal", {
                "Bank Name": openPanModal?.bankDetails?.bank,
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
          >
            <button className="button">
              Continue <i className="shine"></i>
            </button>
          </div>
        </div>
      </div>

      <ConfirmBankModal
        openPanModal={openPanModal}
        setOpenPanModal={setOpenPanModal}
        handleConfirmBankAccount={handleConfirmBankAccount}
      />
    </>
  );
}
